const comments = [
  { name: "pikachu-lover-99", comment: "So in love with this aesthetic!" },
  {
    name: "weedguy420",
    comment:
      "Your new album is a perfect pairing with some nice fresh 'trees'! Know what I mean?",
  },
  { name: "Ooly", comment: "Wanna jam this weekend? I'll be in town." },
  {
    name: "FieldGuy",
    comment: "Can I borrow your van to move some stuff on Saturday?",
  },
  {
    name: "PonyGirl1986",
    comment: "Your music makes me feel alright.",
  },
  {
    name: "digimonFriend73",
    comment:
      "Just got my church welcome package in the mail! So glad I joined the congregation.",
  },
  {
    name: "Bubbles23",
    comment: "Just ordered the new album. Dangerously Stoked.",
  },
  { name: "tamagotchi-babysitter-1999", comment: "My new favourite artist!" },
  { name: "ScoobyDoobie", comment: "Let's go for a rip bud!" },
  { name: "BrotherRoman", comment: "I love playing the drums in your band" },
  { name: "MamaTizzy", comment: "Hope you're having a wonderful week!" },
  { name: "UncleCoreyBassGuy2000", comment: "E A D G 4 Lyfe!" },
  {
    name: "AustySixString",
    comment: "Can you send the tabs for the new song?",
  },
  { name: "PogsRock89", comment: "Cards tomorrow? Maybe some cribbage?" },
  { name: "mtv4Lyfe69", comment: "Your youtube videos are so dreamy!" },
  { name: "sportsRfun", comment: "Keep your stick on the ice!" },
  {
    name: "nursePal29",
    comment: "I hope you are eating well and getting enough sleep",
  },
  {
    name: "BoyGoldenUnofficialFanClub",
    comment: "When is the next album coming out? We can't wait any longer.",
  },
  {
    name: "Samantha1234",
    comment: "You remind me of Ricky Martin meets the Backstreet Boys.",
  },
  {
    name: "Boy Golden",
    comment: "Just making sure this dang thing works. Hello everyone!",
  },
  {
    name: "Tom",
    comment: "Welcome to Myspace Boy Golden!",
  },
  {
    name: "ToyotaPreviaClub",
    comment: "Your van is sick!",
  },
  {
    name: "Tony Hawk",
    comment: "We need to chat about getting your tunes in THPS2",
  },
  {
    name: "DeadHeadzUnited",
    comment: "I think I may have just gotten a bit too crispy",
  },
  {
    name: "PonyLover_145",
    comment: "Please add me as your friend! I just joined on here.",
  },
  {
    name: "ICQ_4_U",
    comment: "LOL, BRB, hahahahah",
  },
  {
    name: "cool_stuff_12",
    comment: "Your church has really changed my life in a good way! Thank you.",
  },
  {
    name: "pilsner_fans",
    comment: "Real big fan of yours! Keep on keeping on! ",
  },
  {
    name: "little_stevie_brule",
    comment: "I know how to Skr8board too! ",
  },
];

export default comments;
